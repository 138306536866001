@font-face {
  font-family: "Fondamento";
  src: local("Fondamento"),
    url("./assets/Fondamento-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./assets/Lato/Lato-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "DMSans";
  src: local("DMSans"), url("./assets/DMSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/Montserrat/Montserrat-VariableFont_wght.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
    url("./assets/Oswald/Oswald-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url("./assets/Raleway/Raleway-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "WorkSans";
  src: local("WorkSans"),
    url("./assets/WorkSans-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Syne";
  src: local("Syne"),
    url("./assets/Syne/Syne-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Sriracha";
  src: local("Sriracha"),
    url("./assets/Sriracha-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Spectral";
  src: local("Spectral"),
    url("./assets/Spectral-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SpaceGrotesk";
  src: local("SpaceGrotesk"),
    url("./assets/SpaceGrotesk-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SourceSansPro";
  src: local("SourceSansPro"),
    url("./assets/SourceSansPro-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Sora";
  src: local("Sora"),
    url("./assets/Sora-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Sofia";
  src: local("Sofia"), url("./assets/Sofia-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "ShortStack";
  src: local("ShortStack"),
    url("./assets/ShortStack-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "ShadowsIntoLight";
  src: local("ShadowsIntoLight"),
    url("./assets/ShadowsIntoLight-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SedgwickAve";
  src: local("SedgwickAve"),
    url("./assets/SedgwickAve-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SedgwickAveDisplay";
  src: local("SedgwickAveDisplay"),
    url("./assets/SedgwickAveDisplay-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "RockSalt";
  src: local("RockSalt"),
    url("./assets/RockSalt-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Redressed";
  src: local("Redressed"),
    url("./assets/Redressed-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url("./assets/Quicksand-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PTSerif";
  src: local("PTSerif"), url("./assets/PTSerif-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: local("PlayfairDisplay"),
    url("./assets/PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PetitFormalScript";
  src: local("PetitFormalScript"),
    url("./assets/PetitFormalScript-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PermanentMarker";
  src: local("PermanentMarker"),
    url("./assets/PermanentMarker-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "RoboPacificoto";
  src: local("RoboPacificoto"),
    url("./assets/Pacifico-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Oxygen";
  src: local("Oxygen"), url("./assets/Oxygen-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OldStandardTT";
  src: local("OldStandardTT"),
    url("./assets/OldStandardTT-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Neucha";
  src: local("Neucha"), url("./assets/Neucha-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Merienda";
  src: local("Merienda"),
    url("./assets/Merienda-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MeriendaOne";
  src: local("MeriendaOne"),
    url("./assets/MeriendaOne-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./assets/Manrope-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: local("Lora"),
    url("./assets/Lora-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "LibreFranklin";
  src: local("LibreFranklin"),
    url("./assets/LibreFranklin-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "LibreBaskerville";
  src: local("LibreBaskerville"),
    url("./assets/LibreBaskerville-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "KleeOne";
  src: local("KleeOne"), url("./assets/KleeOne-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Kalam";
  src: local("Kalam"), url("./assets/Kalam-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "JetBrainsMono";
  src: local("JetBrainsMono"),
    url("./assets/JetBrainsMono-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/Inter-VariableFont_slnt,wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "IndieFlower";
  src: local("IndieFlower"),
    url("./assets/IndieFlower-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Handlee";
  src: local("Handlee"), url("./assets/Handlee-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Hahmlet";
  src: local("Hahmlet"),
    url("./assets/Hahmlet-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "GreatVibes";
  src: local("GreatVibes"),
    url("./assets/GreatVibes-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "GloriaHallelujah";
  src: local("GloriaHallelujah"),
    url("./assets/GloriaHallelujah-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "FrankRuhlLibre";
  src: local("FrankRuhlLibre"),
    url("./assets/FrankRuhlLibre-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "FiraSans";
  src: local("FiraSans"), url("./assets/FiraSans-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Epilogue";
  src: local("Epilogue"),
    url("./assets/Epilogue-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "EncodeSans";
  src: local("EncodeSans"),
    url("./assets/EncodeSans-VariableFont_wdth,wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Eczar";
  src: local("Eczar"),
    url("./assets/Eczar-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "DeliusSwashCaps";
  src: local("DeliusSwashCaps"),
    url("./assets/DeliusSwashCaps-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "CraftyGirls";
  src: local("CraftyGirls"),
    url("./assets/CraftyGirls-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "CoveredByYourGrace";
  src: local("CoveredByYourGrace"),
    url("./assets/CoveredByYourGrace-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Courgette";
  src: local("Courgette"),
    url("./assets/Courgette-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "CormorantGaramond";
  src: local("CormorantGaramond"),
    url("./assets/CormorantGaramond-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Charm";
  src: local("Charm"), url("./assets/Charm-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Cardo";
  src: local("Cardo"), url("./assets/Cardo-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "BioRhyme";
  src: local("BioRhyme"),
    url("./assets/BioRhyme-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Archivo";
  src: local("Archivo"),
    url("./assets/Archivo-VariableFont_wdth,wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "ArchitectsDaughter";
  src: local("ArchitectsDaughter"),
    url("./assets/ArchitectsDaughter-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Anton";
  src: local("Anton"), url("./assets/Anton-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "AndadaPro";
  src: local("AndadaPro"),
    url("./assets/AndadaPro-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}
