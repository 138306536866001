/* .loggingModal {
    width: 400px;
    margin: 0 auto;
    display: inline-block !important;
    left: 50%;
    transform: translateX(-225px);
} */

.btn-primary {
  color: #fff !important;
  background-color: #727cf5 !important;
  border-color: #727cf5 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6169d0 !important;
  border-color: #5b63c4 !important;
}

.modal-dialog {
  width: 430px !important;
}

.modal-content {
  border-radius: 12px !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.modal-backdrop {
  margin: 0;
  height: 100vh;
  font-weight: 100;
  background: radial-gradient(#000000, black);
  -webkit-overflow-y: hidden;
  -moz-overflow-y: hidden;
  -o-overflow-y: hidden;
  overflow-y: hidden;
  -webkit-animation: fadeIn 1 1s ease-out;
  -moz-animation: fadeIn 1 1s ease-out;
  -o-animation: fadeIn 1 1s ease-out;
  animation: fadeIn 1 1s ease-out;
}

#theLogoSpin {
  animation-name: animate-logo-position, animate-logo-scale;
  animation-duration: 15s, 7s;
  animation-timing-function: ease-in-out, linear;
  animation-iteration-count: infinite, infinite;
  animation-delay: 0s, 0s;
  animation-fill-mode: both, both;
}

.buttonModalClose {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  margin-top: 5px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  box-shadow: none !important;
  border: 0px !important;
}
.buttonModalClose:hover {
  background-color: #13152c !important;
}
.buttonModalCloseActivation {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  box-shadow: none !important;
  border: 0px !important;
}
.buttonModalCloseActivation:hover {
  background-color: #13152c !important;
}
.modal-header {
  padding: 0px !important;
  border: 0 !important;
}

.loginRegisterHeader {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #adb5bd;

  /*  border-bottom: 1px solid #727cf5; */
  /* box-shadow: 0px 2px 6px 0px rgba(114, 124, 245, 0.5); */
}

.regloginRegisterIcons {
  padding: 12px 40px 2px 40px;
  width: 100%;
  display: block;
  background-color: #13152c;
}

.loginRegisterIcons {
  width: 100%;
  display: block;
  background-color: #13152c;
}

.singleIconText {
  font-size: 14px;
}

.singleIconTextReg {
  font-size: 12px;
  color: lightgray;
}

.singleIconTextRegActive {
  font-size: 12px;
  color: lightgray;
}

.singleIconTextRegCompleted {
  font-size: 12px;
  color: rgb(0, 184, 18);
}

.singleIcon {
  font-size: 25px;
  display: block;
}

.singleIconReg {
  font-size: 21px;
  display: block;
  margin: 0 auto;
  width: 36px;
  height: 36px;
  color: #727cf5;
  border: 2px solid #727cf5;
  border-radius: 100%;
}

.singleIconRegActive {
  font-size: 21px;
  display: block;
  margin: 0 auto;
  width: 36px;
  height: 36px;
  color: white;
  border: 2px solid rgb(0, 184, 18);
  border-radius: 100%;
}

.singleIconRegCompleted {
  font-size: 21px;
  display: block;
  margin: 0 auto;
  width: 36px;
  height: 36px;
  color: rgb(0, 184, 18);
  border: 2px solid rgb(0, 184, 18);
  border-radius: 100%;
}

.registerLoginNavIconCompleted {
  text-align: center;
  display: inline-block;
  color: green;
  width: 20%;
  padding: 5px;
}

.loginNavIcon {
  text-align: center;
  display: inline-block;
  color: white;
  width: 33.33%;
  padding: 10px;
  cursor: pointer;
}

.loginNavIcon:hover {
  background-color: #222650;
}

.loginNavIconActive {
  text-align: center;
  display: inline-block;
  color: white;
  width: 33%;
  padding: 10px;
  background-color: #635bff;
  cursor: pointer;
}

.registerLoginNavIconActive {
  text-align: center;
  display: inline-block;
  color: white;
  width: 20%;
  padding: 5px;
}

.registerLoginNavIcon {
  text-align: center;
  display: inline-block;
  color: white;
  width: 20%;
  padding: 5px;
}

.nextRegArrowCompleted {
  width: 6.66%;
  font-size: 35px;
  display: inline-block;
  position: relative;
  bottom: 23px;
  left: -5px;
  color: rgb(0, 184, 18);
}

.nextRegArrow {
  width: 6.66%;
  font-size: 35px;
  display: inline-block;
  position: relative;
  bottom: 23px;
  left: -5px;
  color: #727cf5;

  animation-name: animate-arrow-scale;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  animation-fill-mode: both;
}
/* @keyframes animate-rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

@keyframes animate-arrow-scale {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animate-logo-position {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-35px, 0px);
  }
  50% {
    transform: translate(0px, 0px);
  }
  75% {
    transform: translate(35px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.modal-content {
  border: 1px solid #727cf5 !important;
  box-shadow: 0px 2px 6px 0px rgba(114, 124, 245, 0.5);
}

.loggingModal {
}

.registerModal {
}

.loginRegister .form-control {
  display: inline-block;
  padding: 0.3125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #13152c !important;
  background-clip: padding-box !important;
  height: 36px;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: black;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.loginRegister .input-group-text {
  background-color: #13152c;
  color: #adb5bd;
  border: 1px solid rgb(118, 119, 141);
}

.loginRegister input:autofill {
  color: white !important;
  background: #13152c !important;
}

.loginRegister input:-webkit-autofill,
.loginRegister input:-webkit-autofill:hover,
.loginRegister input:-webkit-autofill:focus,
.loginRegister input:-webkit-autofill:active {
  -webkit-text-fill-color: rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0 0 0px 1000px #13152c inset;
  -webkit-transition: background-color 5e3s ease-in-out 0s;
  -moz-transition: background-color 5e3s ease-in-out 0s;
  -o-transition: background-color 5e3s ease-in-out 0s;
  transition: background-color 5e3s ease-in-out 0s;
}

.loginRegister input:active {
  color: white !important;
  background: #13152c !important;
}

.loginRegister input:-webkit-autofill {
  color: white !important;
  background: #13152c !important;
}

#emailUniIcon {
  padding: 0px 5px 4px 5px !important;
  font-size: 20px;
  color: white !important;
}

#emailUniIcon::placeholder {
  color: white !important;
}

.emailUniIcon {
  padding: 0px 4px !important;
}

.landUniIconContainer > div {
  background-color: #13152c !important;
  color: white !important;
  border: 0px;
}

.landUniIconContainer input {
  color: white !important;
}

div.landUniIconContainer input {
  color: white !important;
}

div.landUniIconContainer > input::placeholder {
  color: white !important;
}

#passwordUniIcon {
  padding: 0rem 0.25rem;
  font-size: 20px;
}

#passwordUniIcon:hover {
  background-color: #222650;
}

.emailUniIconContainer {
  padding: 0 !important;
  height: 36px !important;
  width: 38px;
}

.input-group {
  height: 36px !important;
}

.passwordInput {
  display: inline-block;
  padding: 0.3125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #13152c;
  background-clip: padding-box;
  border: 1px solid rgb(118, 119, 141);
  appearance: none;
  border-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  box-shadow: black;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.inputEmailPw {
  color: white !important;
  border: 1px solid rgb(118, 119, 141) !important;
}

.inputEmailPw:focus {
  color: white;
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
    190px 16.33333px #002bff, -113px -308.66667px #ff009d,
    -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
    -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
    -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
    -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
    -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
    69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
    -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
    -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
    140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
    118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
    36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
    -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
    91px -278.66667px #000dff, -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
    -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
    -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
    244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
    141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
    181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
    100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.animate-charcter {
  position: absolute;
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 80px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.regInputValid {
  border: 1px solid green !important;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 3px green !important;
}

.regInputInvalid {
  border: 1px solid red !important;
  border-radius: 0.25rem;
}

.loginNavIconActiveActivate {
  text-align: center;
  display: inline-block;
  color: white;
  width: 33%;
  padding: 10px;
  background-color: #635bff55;
  cursor: pointer;
  margin-bottom: 13px;
}

.loginNavIconActiveActivate:hover {
  background-color: #635bff;
  font-weight: bold;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}
